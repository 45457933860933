import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SUGGESTIONS_PLACEHOLDER } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import type { FC } from 'react';

import css from './suggestion-skeleton.module.scss';

export const SuggestionSkeleton: FC = () => {
    return (
        <li className={css.item} {...createTestHook(SUGGESTIONS_PLACEHOLDER)}>
            <Skeleton width={250} height={16} />
            <div className={css.product}>
                <Skeleton width={100} height={100} />

                <div className={css.productInfo}>
                    <Skeleton width={200} height={20} />
                    <Skeleton width={50} height={32} />
                </div>
            </div>
        </li>
    );
};
