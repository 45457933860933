import type { ComponentProps, FC } from 'react';

export const NoProductAlternativesIllustration: FC<ComponentProps<'svg'>> = ({
    ...props
}) => {
    return (
        <svg
            width="329"
            height="233"
            viewBox="2 0 329 233"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>No product alternatives</title>
            <circle
                r="114.5"
                transform="matrix(0 1 1 0 176.501 114.5)"
                fill="#F3F6F8"
            />
            <g filter="url(#a)">
                <rect
                    x="60"
                    y="52.849"
                    width="187.02"
                    height="70.99"
                    rx="4"
                    transform="rotate(-6.092 60 52.849)"
                    fill="#fff"
                />
                <path
                    stroke="#E6E9ED"
                    strokeWidth=".25"
                    d="m67.521 123.315 185.964-19.848"
                />
                <rect
                    x="206.198"
                    y="61.517"
                    width="35.785"
                    height="20.651"
                    rx="10.325"
                    transform="rotate(-6.092 206.198 61.517)"
                    fill="#fff"
                    stroke="#00ADE6"
                    strokeWidth=".5"
                />
                <path
                    d="m222.099 66.554 2.236-1.027 1.432-.164.724 6.79 1.678-.18.145 1.356-5.185.553-.144-1.344 1.608-.172-.546-5.117-1.447.577-.501-1.272Z"
                    fill="#00ADE6"
                />
                <rect
                    x="121.665"
                    y="71.079"
                    width="55.887"
                    height="7.932"
                    rx="3.966"
                    transform="rotate(-6.092 121.665 71.08)"
                    fill="#E6E9ED"
                />
                <rect
                    x="123.067"
                    y="84.226"
                    width="20.323"
                    height="7.932"
                    rx="3.966"
                    transform="rotate(-6.092 123.067 84.226)"
                    fill="#E6E9ED"
                />
                <rect
                    x="148.33"
                    y="81.527"
                    width="20.323"
                    height="7.932"
                    rx="3.966"
                    transform="rotate(-6.092 148.33 81.527)"
                    fill="#E6E9ED"
                />
                <path
                    d="M90.383 74.971c.074-.181.191-.34.34-.462.15-.122.327-.203.516-.236a3.95 3.95 0 0 1 .486-1.563 3.815 3.815 0 0 1 1.072-1.213c1.284-.952 3.435-.608 3.884-.734a7.85 7.85 0 0 0 1.867-.714c.28-.278-.002.404-.002.404s-1.264 2.744-2.408 3.584c-.64.525-1.414.85-2.23.936a4.224 4.224 0 0 1-2.376-.444c-.288.154-1.15.853-1.12 1.124a1.007 1.007 0 0 1-.03-.682Z"
                    fill="#12B871"
                />
                <path
                    d="M91.402 77.209s-.173-4.352-.298-5.526c-.07-.645-.357-.615-.357-.615l-1.29.138a.51.51 0 0 0-.24.292.534.534 0 0 0 .021.384c.197.481 1.162 5.434 1.162 5.434s.785.588 1.002-.107Z"
                    fill="#A4877C"
                />
                <path
                    d="M91.384 76.459s.982.547 3.847-.959 4.254-1.954 6.422-.137c2.475 2.071 4.677 6.872 3.504 13.136-1.174 6.264-3.869 8.052-5.88 8.951-2.012.9-5.633.19-5.633.19a5.262 5.262 0 0 1-3.255 1.58c-2.078.222-7.596-.01-11.214-7.811-3.617-7.8-.382-12.243.05-13.104.434-.862 1.666-2.787 3.48-2.981 2.21-.236 6.185 1.258 7.758 1.227l.92-.092Z"
                    fill="#14D055"
                />
            </g>
            <g filter="url(#b)">
                <rect
                    x="93.316"
                    y="98.707"
                    width="211.337"
                    height="80.221"
                    rx="4"
                    transform="rotate(2.66 93.316 98.707)"
                    fill="#fff"
                />
                <g opacity=".6">
                    <rect
                        x="157.764"
                        y="130.93"
                        width="63.154"
                        height="9.025"
                        rx="4"
                        transform="rotate(2.66 157.764 130.93)"
                        fill="#8499AB"
                    />
                    <rect
                        x="157.062"
                        y="145.953"
                        width="22.965"
                        height="9.025"
                        rx="4"
                        transform="rotate(2.66 157.062 145.953)"
                        fill="#8499AB"
                    />
                    <rect
                        x="185.739"
                        y="147.286"
                        width="22.965"
                        height="9.025"
                        rx="4"
                        transform="rotate(2.66 185.739 147.286)"
                        fill="#E6E9ED"
                    />
                </g>
                <circle
                    opacity=".1"
                    cx="126.098"
                    cy="141.496"
                    r="16.616"
                    transform="rotate(-1.61 126.098 141.496)"
                    fill="#8499AB"
                />
                <path
                    d="m121.427 159.288.326-7 6.512.303-.325 7-6.513-.303Zm-2.658-30.571c2.424-3.967 5.647-5.857 9.669-5.67 1.309.061 2.496.332 3.561.812 1.067.448 1.966 1.07 2.697 1.867a7.065 7.065 0 0 1 1.695 2.765c.399 1.047.569 2.216.509 3.506-.071 1.523-.424 2.916-1.058 4.18-.635 1.264-1.64 2.594-3.017 3.989-.572.604-1.026 1.08-1.362 1.429-.334.316-.536.523-.604.619-1.313 1.398-2.209 2.766-2.687 4.104-.112.36-.209.72-.29 1.081-.081.361-.205.969-.372 1.824l-5.363-.25.046-.993c.029-.628.107-1.272.233-1.929.127-.657.313-1.229.56-1.715a13.717 13.717 0 0 1 1.351-2.226c.307-.417.681-.881 1.12-1.391l1.673-1.912c.438-.477.809-.891 1.113-1.242.306-.384.562-.72.767-1.009.237-.287.426-.561.566-.819l.516-.772c.313-.55.56-1.052.742-1.508.182-.489.285-.982.308-1.478.049-1.06-.214-1.934-.79-2.624-.574-.723-1.324-1.106-2.25-1.15a3.855 3.855 0 0 0-2.531.778c-.73.563-1.446 1.509-2.147 2.836l-2.328-1.551-2.327-1.551Z"
                    fill="#00ADE6"
                />
            </g>
            <defs>
                <filter
                    id="a"
                    x=".401"
                    y="5.403"
                    width="313.097"
                    height="210.036"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="8" />
                    <feGaussianBlur stdDeviation="5.009" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_10570_89809"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="32" />
                    <feGaussianBlur stdDeviation="30" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
                    <feBlend
                        in2="effect1_dropShadow_10570_89809"
                        result="effect2_dropShadow_10570_89809"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_10570_89809"
                        result="shape"
                    />
                </filter>
                <filter
                    id="b"
                    x="65.656"
                    y="90.888"
                    width="262.706"
                    height="141.7"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="20" />
                    <feGaussianBlur stdDeviation="12.059" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0456112 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_10570_89809"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-1" />
                    <feGaussianBlur stdDeviation="3.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                    <feBlend
                        in2="effect1_dropShadow_10570_89809"
                        result="effect2_dropShadow_10570_89809"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_10570_89809"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
