'use client';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    SUGGESTIONS_CLOSE,
    SUGGESTIONS_MODAL,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronLeft16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-16';
import { Close16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/close-16';
import {
    Modal,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { AnimatePresence, motion } from 'framer-motion';
import { type FC, useEffect, useRef } from 'react';
import type { RecipeDetailsServing } from '../../../graphql/_generated-graphql-types';
import type { ProductSuggestionFragment } from '../../../graphql/_generated-operation-types';
import { ProductAlternativesPanel } from './elements/product-alternatives-panel/product-alternatives-panel';
import { ProductSuggestionsPanel } from './elements/product-suggestions-panel/product-suggestions-panel';
import { VagueTermsPanel } from './elements/vague-terms-panel/vague-terms-panel';
import {
    type ProductSuggestion,
    ProductSuggestionsState,
} from './recipe-product-suggestions-modal-hook';
import { getModalTranslations } from './utils/translations';

import css from './recipe-product-suggestions-modal.module.scss';

interface RecipeProductSuggestionsModalProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;

    state: ProductSuggestionsState;
    suggestions: ProductSuggestion[];

    isOpen: boolean;
    onClose: () => void;
    onBack: () => void;
    isLoading: boolean;

    excludedIngredients: number[];

    servings: RecipeDetailsServing;
    handleOnServingsChange: (newServings: number) => void;

    ingredientId: number | null;

    handleOnOpenAlternatives: (ingredientId: number) => void;
    handleOnSelectAlternative: (
        productSuggestion: ProductSuggestionFragment,
        index: number,
    ) => void;

    handleOnOpenVagueTerms: (ingredientId: number) => void;
    handleOnSelectVagueTerm: (productId: number, index: number) => void;

    handleOnIncreaseProductQuantity: (ingredientId: number) => void;
    handleOnDecreaseProductQuantity: (ingredientId: number) => void;
    handleOnSetProductQuantity: (
        ingredientId: number,
        quantity: number,
    ) => void;

    handleOnAddToBasket: () => void;
}

export const RecipeProductSuggestionsModal: FC<
    RecipeProductSuggestionsModalProps
> = ({
    locale = DEFAULT_LOCALE,

    state,
    isOpen,
    onClose,
    onBack,
    suggestions,
    excludedIngredients,
    servings,
    isLoading,

    handleOnServingsChange,

    ingredientId,

    handleOnOpenAlternatives,
    handleOnSelectAlternative,

    handleOnOpenVagueTerms,
    handleOnSelectVagueTerm,

    handleOnIncreaseProductQuantity,
    handleOnDecreaseProductQuantity,
    handleOnSetProductQuantity,

    handleOnAddToBasket,
}) => {
    const ref = useRef<HTMLDialogElement>(null);

    const selectedIngredient = ingredientId
        ? suggestions.find(e => e.ingredient.id === ingredientId)
        : null;

    const { t } = getModalTranslations(locale);

    // biome-ignore lint/correctness/useExhaustiveDependencies: reset focus on every state change, else the focus is set on the full window
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [state]);

    return (
        <Modal
            ref={ref}
            data-brand="ah"
            isOpen={isOpen}
            onClose={onClose}
            className={css.modal}
            {...createTestHook(SUGGESTIONS_MODAL)}
        >
            <ModalHeader className={css.modalHeader}>
                {[
                    ProductSuggestionsState.ALTERNATIVES,
                    ProductSuggestionsState.VAGUE_TERMS,
                ].includes(state) && (
                    <ModalHeaderIconButton
                        onClick={onBack}
                        aria-label={t('action.back')}
                    >
                        <ChevronLeft16Icon size={16} aria-hidden="true" />
                    </ModalHeaderIconButton>
                )}
                <ModalHeaderTitle
                    variant="body-extra-strong"
                    aria-live="polite"
                >
                    {state === ProductSuggestionsState.ALTERNATIVES
                        ? selectedIngredient?.alternativeSections.length
                            ? t('alternatives.header')
                            : null
                        : state === ProductSuggestionsState.VAGUE_TERMS
                          ? t('vagueTerms.header')
                          : t('suggestions.header')}
                </ModalHeaderTitle>
                {![
                    ProductSuggestionsState.ALTERNATIVES,
                    ProductSuggestionsState.VAGUE_TERMS,
                ].includes(state) && (
                    <ModalHeaderIconButton
                        onClick={onClose}
                        aria-label={t('action.close')}
                        {...createTestHook(SUGGESTIONS_CLOSE)}
                    >
                        <Close16Icon size={16} aria-hidden="true" />
                    </ModalHeaderIconButton>
                )}
            </ModalHeader>

            <div className={css.container}>
                <AnimatePresence>
                    {state === ProductSuggestionsState.PRODUCT_SUGGESTIONS && (
                        <motion.div
                            className={css.panel}
                            key="product-suggestions"
                            initial={{
                                translateX: '0',
                                zIndex: 0,
                            }}
                            animate={{ translateX: '0', zIndex: 0 }}
                            exit={{ translateX: '-100%', zIndex: 1 }}
                            transition={{ ease: 'linear' }}
                        >
                            <ProductSuggestionsPanel
                                locale={locale}
                                servings={servings}
                                excludedIngredients={excludedIngredients}
                                suggestions={suggestions}
                                isLoading={isLoading}
                                handleOnServingsChange={handleOnServingsChange}
                                handleOnOpenAlternatives={
                                    handleOnOpenAlternatives
                                }
                                handleOnOpenVagueTerms={handleOnOpenVagueTerms}
                                handleOnIncreaseProductQuantity={
                                    handleOnIncreaseProductQuantity
                                }
                                handleOnDecreaseProductQuantity={
                                    handleOnDecreaseProductQuantity
                                }
                                handleOnSetProductQuantity={
                                    handleOnSetProductQuantity
                                }
                                handleOnAddToBasket={handleOnAddToBasket}
                            />
                        </motion.div>
                    )}

                    {state === ProductSuggestionsState.ALTERNATIVES &&
                        selectedIngredient && (
                            <motion.div
                                className={css.panel}
                                key="alternatives"
                                initial={{ translateX: '0', zIndex: 0 }}
                                animate={{
                                    translateX: '0',
                                    zIndex: 0,
                                }}
                                exit={{ translateX: '100%', zIndex: 1 }}
                                transition={{ ease: 'linear' }}
                            >
                                <ProductAlternativesPanel
                                    locale={locale}
                                    handleOnSelectAlternative={
                                        handleOnSelectAlternative
                                    }
                                    alternativeSections={
                                        selectedIngredient.alternativeSections
                                    }
                                    onBack={onBack}
                                    selectedIngredient={selectedIngredient}
                                />
                            </motion.div>
                        )}

                    {state === ProductSuggestionsState.VAGUE_TERMS &&
                        selectedIngredient && (
                            <motion.div
                                className={css.panel}
                                key="vague-terms"
                                initial={{ translateX: '0', zIndex: 0 }}
                                animate={{
                                    translateX: '0',
                                    zIndex: 0,
                                }}
                                exit={{ translateX: '100%', zIndex: 1 }}
                                transition={{ ease: 'linear' }}
                            >
                                <VagueTermsPanel
                                    locale={locale}
                                    ingredient={selectedIngredient}
                                    handleOnSelectVagueTerm={
                                        handleOnSelectVagueTerm
                                    }
                                    onBack={onBack}
                                />
                            </motion.div>
                        )}
                </AnimatePresence>
            </div>
        </Modal>
    );
};
