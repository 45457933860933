import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SUGGESTIONS_INGREDIENT } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { VisuallyHidden } from '@royalaholddelhaize/design-system-pantry-web/components/utilities/visually-hidden/visually-hidden';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, PropsWithChildren } from 'react';
import type { ProductSuggestion } from '../../recipe-product-suggestions-modal-hook';
import { getModalTranslations } from '../../utils/translations';
import { VagueTerm } from '../vague-term/vague-term';

import { normalizeMetricUnits } from '../../../../../utils/metric-units';
import css from './ingredient.module.scss';

interface IngredientProps extends PropsWithChildren {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    ingredient: ProductSuggestion['ingredient'];
    onClick: (ingredientId: number) => void;
}

export const Ingredient: FC<IngredientProps> = ({
    locale = DEFAULT_LOCALE,
    children,
    ingredient,
    onClick,
}) => {
    const { t } = getModalTranslations(locale);
    const { quantity, unit } = normalizeMetricUnits(
        ingredient.quantityFloat,
        ingredient.quantityUnit,
    );
    const ingredientName = `${+(quantity || 0).toFixed(2)} ${unit || ''} ${ingredient.name}`;

    const handleOnClick = () => {
        onClick(ingredient.id || 0);
    };

    return (
        <li className={css.item} {...createTestHook(SUGGESTIONS_INGREDIENT)}>
            <Typography variant="body-regular" className={css.ingredient}>
                {children ? (
                    <span>{t('ingredient.pre')}</span>
                ) : (
                    <VisuallyHidden>{t('ingredient.pre')}</VisuallyHidden>
                )}
                {children && ' '}
                <span>{ingredientName}</span>
                {children && ':'}
            </Typography>
            {children || (
                <VagueTerm
                    ingredientName={ingredientName}
                    onClick={handleOnClick}
                />
            )}
        </li>
    );
};
