'use client';

import { DEFAULT_LOCALE } from '@royalaholddelhaize/ah-web-core';
import { Portal } from '@royalaholddelhaize/design-system-pantry-web/components/utilities/portal/portal';
import type { FC, PropsWithChildren } from 'react';
import { AddToBasketFailureModal } from '../components/modals/recipe-product-suggestions-modal/elements/add-to-basket-failure-modal/add-to-basket-failure-modal';
import { AddToBasketSuccessModal } from '../components/modals/recipe-product-suggestions-modal/elements/add-to-basket-success-modal/add-to-basket-success-modal';
import { RecipeProductSuggestionsModal } from '../components/modals/recipe-product-suggestions-modal/recipe-product-suggestions-modal';
import {
    ProductSuggestionsState,
    type UseRecipeProductSuggestionsModal,
    useRecipeProductSuggestionsModal,
} from '../components/modals/recipe-product-suggestions-modal/recipe-product-suggestions-modal-hook';
import { RecipeProductSuggestionsContext } from '../contexts/recipe-product-suggestions-context';

type RecipeProductSuggestionsProviderProps = PropsWithChildren &
    UseRecipeProductSuggestionsModal;

export const RecipeProductSuggestionsProvider: FC<
    RecipeProductSuggestionsProviderProps
> = ({
    children,
    locale = DEFAULT_LOCALE,
    recipeId,
    initialServings,
    member,
}) => {
    const {
        state,
        setState,
        suggestions,
        isModalOpen,
        handleOnClose,
        handleOnOpen,
        isLoading,

        servings,
        handleOnServingsChange,
        excludedIngredients,
        handleOnExcludeIngredients,

        ingredientId,

        handleOnOpenAlternatives,
        handleOnSelectAlternative,

        handleOnOpenVagueTerms,
        handleOnSelectVagueTerm,

        handleOnIncreaseProductQuantity,
        handleOnDecreaseProductQuantity,
        handleOnSetProductQuantity,

        handleOnAddToBasket,
    } = useRecipeProductSuggestionsModal({
        recipeId,
        initialServings,
        member,
    });

    const handleOnBack = () => {
        setState(ProductSuggestionsState.PRODUCT_SUGGESTIONS);
    };

    return (
        <RecipeProductSuggestionsContext.Provider
            value={{
                isModalOpen,
                handleOnOpen,
                handleOnClose,
                servings,
                handleOnServingsChange,
                excludedIngredients,
                handleOnExcludeIngredients,
            }}
        >
            {children}
            <Portal>
                {state === ProductSuggestionsState.ADD_TO_BASKET_SUCCESS && (
                    <AddToBasketSuccessModal
                        locale={locale}
                        isOpen={isModalOpen}
                        onClose={handleOnClose}
                        onBack={handleOnBack}
                    />
                )}

                {state === ProductSuggestionsState.ADD_TO_BASKET_FAILURE && (
                    <AddToBasketFailureModal
                        locale={locale}
                        isOpen={isModalOpen}
                        onClose={handleOnClose}
                        onBack={handleOnBack}
                    />
                )}

                {![
                    ProductSuggestionsState.ADD_TO_BASKET_SUCCESS,
                    ProductSuggestionsState.ADD_TO_BASKET_FAILURE,
                ].includes(state) && (
                    <RecipeProductSuggestionsModal
                        locale={locale}
                        state={state}
                        isOpen={isModalOpen}
                        onClose={handleOnClose}
                        onBack={handleOnBack}
                        servings={servings}
                        excludedIngredients={excludedIngredients}
                        suggestions={suggestions}
                        isLoading={isLoading}
                        handleOnServingsChange={handleOnServingsChange}
                        ingredientId={ingredientId}
                        handleOnOpenAlternatives={handleOnOpenAlternatives}
                        handleOnSelectAlternative={handleOnSelectAlternative}
                        handleOnOpenVagueTerms={handleOnOpenVagueTerms}
                        handleOnSelectVagueTerm={handleOnSelectVagueTerm}
                        handleOnIncreaseProductQuantity={
                            handleOnIncreaseProductQuantity
                        }
                        handleOnDecreaseProductQuantity={
                            handleOnDecreaseProductQuantity
                        }
                        handleOnSetProductQuantity={handleOnSetProductQuantity}
                        handleOnAddToBasket={handleOnAddToBasket}
                    />
                )}
            </Portal>
        </RecipeProductSuggestionsContext.Provider>
    );
};
