import { useRecipeShoppableSaveSelectionMutation } from '../_generated-hooks';
import {
    type RecipeProductSuggestionsFragment,
    RecipeShoppableProductSuggestionProposer,
    type RecipeShoppableSelection,
    RecipeShoppableSelectionAction,
} from '../_generated-operation-types';

const mapProductSuggestion = ({
    ingredient,
    productSuggestion,
}: RecipeProductSuggestionsFragment): RecipeShoppableSelection => {
    const newQuantityUnit = ingredient.quantityUnit
        ? {
              singular: ingredient.quantityUnit,
              plural: ingredient.quantityUnit,
          }
        : null;

    return {
        action: RecipeShoppableSelectionAction.ADD,
        ingredient: {
            id: ingredient.id,
            quantity: ingredient.quantityFloat ?? 0,
            name: {
                singular: ingredient.quantityUnit || '',
                plural: ingredient.quantityUnit,
            },
            quantityUnit: newQuantityUnit,
        },
        product: {
            id: productSuggestion?.id ?? 0,
            quantity: productSuggestion?.quantity ?? 0,
            proposer:
                productSuggestion?.proposer ||
                RecipeShoppableProductSuggestionProposer.A,
        },
    };
};

const normalizeProductSuggestionsToSelections = (
    productSuggestions: RecipeProductSuggestionsFragment[],
): RecipeShoppableSelection[] => productSuggestions.map(mapProductSuggestion);

export const useMemberProductSelection = () => {
    const [saveIngredients] = useRecipeShoppableSaveSelectionMutation();

    const saveMemberProductSelection = ({
        recipeId,
        numberOfServings,
        ingredients,
    }: {
        recipeId: number | null;
        numberOfServings: number;
        ingredients: RecipeProductSuggestionsFragment[];
    }) => {
        const selections = normalizeProductSuggestionsToSelections(ingredients);

        saveIngredients({
            variables: {
                params: {
                    recipeId: recipeId || 0,
                    servingNumber: numberOfServings,
                    selections,
                },
            },
        }).catch();
    };

    return {
        saveMemberProductSelection,
    };
};
