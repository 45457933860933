import {
    type ComponentMeta,
    emitClickComponent,
} from '@royalaholddelhaize/ah-analytics';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import { EditPen24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/edit-pen-24';
import {
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { getModalTranslations } from '../../utils/translations';

import css from './vague-term.module.scss';

interface VagueTermProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    ingredientName: string;
    onClick: () => void;
}

export const VagueTerm: FC<VagueTermProps> = ({
    locale = DEFAULT_LOCALE,
    ingredientName,
    onClick,
}) => {
    const { t } = getModalTranslations(locale);

    const handleOnClick = () => {
        const cidComponent: ComponentMeta = {
            componentType: 'Regular link',
            componentSubType: 'link',
            componentInnerText: t('ingredient.chooseProduct'),
            componentTitle: 'Productsuggestion',
            componentSection: 'Allerhande - Recipes',
            componentAdditional: ingredientName,
        };

        emitClickComponent(cidComponent);

        onClick();
    };

    return (
        <div className={css.root}>
            <EditPen24Icon aria-hidden className={css.icon} size={32} />
            <div>
                <Typography aria-hidden>
                    {ingredientName} {t('ingredient.suffix')}
                </Typography>
                <LinkButton className={css.link} onClick={handleOnClick}>
                    <LinkText>{t('ingredient.chooseProduct')}</LinkText>
                    <ChevronRight16Icon />
                </LinkButton>
            </div>
        </div>
    );
};
