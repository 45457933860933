import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';

import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { getModalTranslations } from '../../utils/translations';
import { NoProductAlternativesIllustration } from './no-product-alternatives-illustration';

import css from './no-product-alternatives-panel.module.scss';

interface NoProductAlternativesProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    onBack: () => void;
}

export const NoProductAlternatives: FC<NoProductAlternativesProps> = ({
    locale = DEFAULT_LOCALE,
    onBack,
}) => {
    const { t } = getModalTranslations(locale);

    return (
        <div className={css.content}>
            <NoProductAlternativesIllustration className={css.illustration} />
            <Typography variant="heading-2">
                {t('alternatives.title')}
            </Typography>
            <Typography align="center">
                {t('alternatives.description')}
            </Typography>
            <RegularButton className={css.button} onClick={onBack}>
                <RegularButtonText>{t('alternatives.cta')}</RegularButtonText>
            </RegularButton>
        </div>
    );
};
