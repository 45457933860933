import {
    type ProductMetaProps,
    emitViewProductList,
} from '@royalaholddelhaize/ah-analytics';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SUGGESTIONS_ALTERNATIVE_PRODUCTS_PANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ModalContent } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { type FC, Fragment, useEffect } from 'react';
import type { ProductSuggestionFragment } from '../../../../../graphql/_generated-operation-types';
import type { ProductSuggestion } from '../../recipe-product-suggestions-modal-hook';
import { NoProductAlternatives } from '../no-product-alternatives/no-product-alternatives-panel';
import { Product } from '../product/product';

import css from './product-alternatives-panel.module.scss';

interface ProductAlternativesPanelProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    alternativeSections: ProductSuggestion['alternativeSections'];
    handleOnSelectAlternative: (
        productSuggestion: ProductSuggestionFragment,
        index: number,
    ) => void;
    onBack: () => void;
    selectedIngredient: ProductSuggestion;
}

export const convertAlternativeSectionsToCidProducts = (
    alternativeSections: ProductSuggestion['alternativeSections'],
    suggestion?: ProductSuggestion,
): ProductMetaProps[] =>
    alternativeSections
        .filter(alternative => alternative.productSuggestions.length > 0)
        .flatMap(alternative => alternative.productSuggestions)
        .map((alternative, index) => {
            return {
                id: alternative?.product?.id ?? 0,
                index,
                listName: 'shoppable_recipe_alternative',
                parentProductId: suggestion?.productSuggestion?.id,
            };
        });

export const ProductAlternativesPanel: FC<ProductAlternativesPanelProps> = ({
    locale = DEFAULT_LOCALE,
    alternativeSections,
    handleOnSelectAlternative,
    onBack,
    selectedIngredient,
}) => {
    const alternatives = alternativeSections.flatMap(e => e.productSuggestions);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (alternativeSections) {
            emitViewProductList(
                convertAlternativeSectionsToCidProducts(
                    alternativeSections,
                    selectedIngredient,
                ),
            );
        }
    }, []);

    return (
        <ModalContent
            className={css.modalContent}
            {...createTestHook(SUGGESTIONS_ALTERNATIVE_PRODUCTS_PANE)}
        >
            {alternativeSections.length === 0 && (
                <NoProductAlternatives locale={locale} onBack={onBack} />
            )}

            {alternatives.length > 0 &&
                alternativeSections.map(
                    ({ title, description, productSuggestions }) => (
                        <Fragment key={title}>
                            <Typography
                                variant="heading-3"
                                className={css.title}
                            >
                                {title}
                            </Typography>
                            <Typography
                                variant="body-regular"
                                className={css.description}
                            >
                                {description}
                            </Typography>
                            <ul className={css.list}>
                                {(
                                    productSuggestions as ProductSuggestionFragment[]
                                ).map((productSuggestion, idx) => (
                                    <li
                                        key={productSuggestion?.id}
                                        className={css.item}
                                    >
                                        <Product
                                            product={productSuggestion.product}
                                            quantity={0}
                                            onSwap={() =>
                                                handleOnSelectAlternative(
                                                    productSuggestion,
                                                    idx,
                                                )
                                            }
                                            isSelection
                                            index={idx}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </Fragment>
                    ),
                )}
        </ModalContent>
    );
};
