import { createImageSrcSet } from '@helpers/image';
import type { ContentImageSet } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import type { RecipeImage } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/_generated-graphql-types';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import css from './composed-image.module.scss';

export interface ComposedImageProps extends ComponentProps<'div'> {
    imageGroups: (
        | Omit<RecipeImage, 'rendition' | '__typename'>
        | ContentImageSet
    )[][];
}

export const ComposedImage: FC<ComposedImageProps> = ({
    imageGroups,
    className,
    ...props
}) => {
    const filteredImageGroups = imageGroups
        .slice(0, 4)
        .filter(group => group.length > 0);

    return (
        <div className={classNames(css.grid, className)} {...props}>
            {filteredImageGroups.map((images, idx) => (
                <div
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={idx}
                    className={classNames(css.image, {
                        [css.column]:
                            (filteredImageGroups.length === 3 && idx === 2) ||
                            filteredImageGroups.length === 1,
                    })}
                >
                    <img
                        srcSet={createImageSrcSet(images)}
                        loading="lazy"
                        aria-hidden
                    />
                </div>
            ))}
        </div>
    );
};
