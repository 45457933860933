import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SUGGESTIONS_REUSABLE_INGREDIENT } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { ProductSuggestion } from '../../recipe-product-suggestions-modal-hook';

import css from './reusable-ingredient.module.scss';

interface ReusableIngredientProps {
    ingredient: ProductSuggestion['ingredient'];
    onClick: () => void;
}

export const ReusableIngredient: FC<ReusableIngredientProps> = ({
    ingredient,
    onClick,
}) => {
    return (
        <li {...createTestHook(SUGGESTIONS_REUSABLE_INGREDIENT)}>
            <button className={css.ingredient} type="button" onClick={onClick}>
                <Typography>{ingredient.name}</Typography>
                <ChevronRight16Icon />
            </button>
        </li>
    );
};
