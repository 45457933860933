'use client';

import { createContext } from 'react';
import type { RecipeDetailsServing } from '../graphql/_generated-graphql-types';

export type RecipeProductSuggestionsData = {
    isModalOpen: boolean;
    handleOnClose: () => void;
    handleOnOpen: () => void;
    servings: RecipeDetailsServing;
    handleOnServingsChange: (newServings: number) => void;
    excludedIngredients: number[];
    handleOnExcludeIngredients: (ingredientIds: number[]) => void;
};

export const RecipeProductSuggestionsContext =
    createContext<RecipeProductSuggestionsData>({
        isModalOpen: false,
        handleOnClose: () => null,
        handleOnOpen: () => null,

        servings: {
            min: 1,
            number: 4,
            max: 40,
            scale: 1,
            isChangeable: true,
            type: 'personen',
        },
        handleOnServingsChange: () => {},
        excludedIngredients: [],
        handleOnExcludeIngredients: () => {},
    });
