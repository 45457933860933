import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    SUGGESTIONS_ADD,
    SUGGESTIONS_PRODUCT_CARD,
    SUGGESTIONS_PRODUCT_LIST,
    SUGGESTIONS_PRODUCT_PANE,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { ModalContent } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { RecipeDetailsServing } from '../../../../../graphql/_generated-graphql-types';
import { ServingsInput } from '../../../../elements/servings-input/servings-input';
import type { ProductSuggestion } from '../../recipe-product-suggestions-modal-hook';
import { getModalTranslations } from '../../utils/translations';
import { Ingredient } from '../ingredient/ingredient';
import { Product } from '../product/product';
import { ReusableIngredient } from '../reusable-ingredient/reusable-ingredient';
import { SuggestionSkeleton } from '../suggestion-skeleton/suggestion-skeleton';

import css from './product-suggestions-panel.module.scss';

interface ProductSuggestionsPanelProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;

    servings: RecipeDetailsServing;
    excludedIngredients: number[];
    suggestions: ProductSuggestion[];
    isLoading: boolean;

    handleOnServingsChange: (newServings: number) => void;

    handleOnOpenAlternatives: (ingredientId: number) => void;
    handleOnOpenVagueTerms: (ingredientId: number) => void;

    handleOnIncreaseProductQuantity: (ingredientId: number) => void;
    handleOnDecreaseProductQuantity: (ingredientId: number) => void;
    handleOnSetProductQuantity: (
        ingredientId: number,
        quantity: number,
    ) => void;

    handleOnAddToBasket: () => void;
}

export const ProductSuggestionsPanel: FC<ProductSuggestionsPanelProps> = ({
    locale = DEFAULT_LOCALE,
    servings,
    suggestions,
    excludedIngredients,
    isLoading,
    handleOnServingsChange,
    handleOnOpenAlternatives,
    handleOnOpenVagueTerms,
    handleOnIncreaseProductQuantity,
    handleOnDecreaseProductQuantity,
    handleOnSetProductQuantity,
    handleOnAddToBasket,
}) => {
    const { t } = getModalTranslations(locale);

    const reusableProductSuggestions = suggestions.filter(
        suggestion => suggestion.optional && !suggestion.productSuggestion,
    );

    const shoppableProductSuggestions = suggestions
        .filter(
            e =>
                e.ingredient?.id &&
                !excludedIngredients.includes(e.ingredient.id),
        )
        .filter(
            suggestion => !suggestion.optional || suggestion.productSuggestion,
        );

    const basket = shoppableProductSuggestions
        .filter(suggestion => suggestion.isEnabled)
        .reduce((total, { productSuggestion }) => {
            if (!productSuggestion) {
                return total;
            }
            return total + productSuggestion.quantity;
        }, 0);

    return (
        <>
            <ModalContent
                className={css.modalContent}
                {...createTestHook(SUGGESTIONS_PRODUCT_PANE)}
            >
                <ServingsInput
                    servings={servings}
                    onChange={handleOnServingsChange}
                />

                <ul
                    className={css.list}
                    {...createTestHook(SUGGESTIONS_PRODUCT_LIST)}
                >
                    {isLoading &&
                        [...Array(10)].map((_, idx) => (
                            <SuggestionSkeleton
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                key={`suggestions-skeleton-${idx}`}
                            />
                        ))}

                    {!isLoading &&
                        shoppableProductSuggestions?.length > 0 &&
                        shoppableProductSuggestions
                            .sort((a, b) => {
                                if (
                                    a.productSuggestion &&
                                    !b.productSuggestion
                                ) {
                                    return 1;
                                }

                                if (
                                    !a.productSuggestion &&
                                    b.productSuggestion
                                ) {
                                    return -1;
                                }

                                return 0;
                            })
                            .map(({ ingredient, productSuggestion }, idx) => (
                                <Ingredient
                                    key={`${ingredient.id}`}
                                    ingredient={ingredient}
                                    onClick={handleOnOpenVagueTerms}
                                >
                                    {productSuggestion && (
                                        <Product
                                            quantity={
                                                productSuggestion.quantity
                                            }
                                            product={productSuggestion.product}
                                            onSwap={() =>
                                                handleOnOpenAlternatives(
                                                    ingredient.id || 0,
                                                )
                                            }
                                            onIncrease={() =>
                                                handleOnIncreaseProductQuantity(
                                                    ingredient.id || 0,
                                                )
                                            }
                                            onDecrease={() =>
                                                handleOnDecreaseProductQuantity(
                                                    ingredient.id || 0,
                                                )
                                            }
                                            onUpdate={quantity => {
                                                handleOnSetProductQuantity(
                                                    ingredient.id || 0,
                                                    quantity,
                                                );
                                            }}
                                            index={idx}
                                            {...createTestHook(
                                                SUGGESTIONS_PRODUCT_CARD,
                                            )}
                                        />
                                    )}
                                </Ingredient>
                            ))}
                </ul>

                {!isLoading && reusableProductSuggestions.length > 0 && (
                    <>
                        <Typography
                            variant="heading-3"
                            className={css.reusable}
                        >
                            {t('reusable')}:
                        </Typography>
                        <ul className={css.list}>
                            {reusableProductSuggestions.map(
                                ({ ingredient }) => (
                                    <ReusableIngredient
                                        key={ingredient.id}
                                        ingredient={ingredient}
                                        onClick={() =>
                                            handleOnOpenAlternatives(
                                                ingredient.id || 0,
                                            )
                                        }
                                    />
                                ),
                            )}
                        </ul>
                    </>
                )}
            </ModalContent>

            <div className={css.modalFooter}>
                <RegularButton
                    onClick={handleOnAddToBasket}
                    {...createTestHook(SUGGESTIONS_ADD)}
                >
                    <RegularButtonText>
                        {t('addToCart', { count: basket })}
                    </RegularButtonText>
                </RegularButton>
            </div>
        </>
    );
};
