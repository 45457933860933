import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SUGGESTIONS_VAGUE_TERMS_PRODUCTS_PANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import { DEFAULT_LOCALE, type Locale } from '@royalaholddelhaize/ah-web-core';
import { ModalContent } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';
import type { FC } from 'react';
import { useRecipeVagueSuggestionsSearchQuery } from '../../../../../graphql/_generated-hooks';
import type { ProductSuggestion } from '../../recipe-product-suggestions-modal-hook';
import { NoProductAlternatives } from '../no-product-alternatives/no-product-alternatives-panel';
import { Product } from '../product/product';
import { SuggestionSkeleton } from '../suggestion-skeleton/suggestion-skeleton';

import css from './vague-terms-panel.module.scss';

interface VagueTermsPanelProps {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    ingredient: ProductSuggestion;
    handleOnSelectVagueTerm: (productId: number, index: number) => void;
    onBack: () => void;
}

export const VagueTermsPanel: FC<VagueTermsPanelProps> = ({
    locale = DEFAULT_LOCALE,
    ingredient,
    handleOnSelectVagueTerm,
    onBack,
}) => {
    const { data, loading } = useRecipeVagueSuggestionsSearchQuery({
        variables: {
            input: {
                availableOnline: undefined,
                bonus: undefined,
                bonusPeriodEndDate: undefined,
                bonusPeriodStartDate: undefined,
                inStock: undefined,
                orderId: undefined,
                page: undefined,
                previouslyBought: undefined,
                properties: undefined,
                query: ingredient.ingredient.name,
                referenceDate: undefined,
                size: undefined,
                taxonomyId: undefined,
                viewDate: undefined,
                webshopId: undefined,
            },
        },
    });

    return (
        <ModalContent
            className={css.modalContent}
            {...createTestHook(SUGGESTIONS_VAGUE_TERMS_PRODUCTS_PANE)}
        >
            {!loading && data && data.productSearch.products.length === 0 && (
                <NoProductAlternatives locale={locale} onBack={onBack} />
            )}

            {(loading || (data && data.productSearch.products.length > 0)) && (
                <ul className={css.list}>
                    {loading &&
                        [...Array(10)].map((_, idx) => (
                            <SuggestionSkeleton
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                key={`vague-terms-skeleton-${idx}`}
                            />
                        ))}

                    {!loading &&
                        data?.productSearch.products.map((product, idx) => (
                            <li key={product.id} className={css.item}>
                                <Product
                                    product={product}
                                    quantity={0}
                                    onSwap={() =>
                                        handleOnSelectVagueTerm(product.id, idx)
                                    }
                                    isSelection
                                    index={idx}
                                />
                            </li>
                        ))}
                </ul>
            )}
        </ModalContent>
    );
};
